<template>
  <div :key="'detailInvoiceServicesTable_' + forceUpdate">
    <b-row :class="[{'block-services-add':crud.form.servicesTMP.editing}]">
      <b-col md="8">
        <b-row v-if="!isInvoice">
          <b-col md="8">
            <FindObject :key="crud.form.servicesTMP.forceRenderSelectServices"
                        render="search"
                        type="services" 
                        @select-object="loadServices($event)" 
                        :valueID="crud.form.servicesTMP.services_id"
                        :where="conditionServicesActive"/>           
          </b-col>      
          <b-col md="4">
            <b-form-group label="Inicio">
              <b-form-datepicker  size="sm" 
                                  v-model="crud.form.servicesTMP.date_init" 
                                  placeholder="Fecha" 
                                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                  local="es"></b-form-datepicker>                      
            </b-form-group>            
          </b-col>         
        </b-row>              

        <b-row v-if="isInvoice">
          <b-col md="4">
            <FindObject :key="crud.form.servicesTMP.forceRenderSelectServices"
                        render="search"
                        type="services" 
                        @select-object="loadServices($event)" 
                        :valueID="crud.form.servicesTMP.services_id"
                        :where="conditionServicesActive"/>           
          </b-col>      
          <b-col md="8">
            <b-form-group label="Descripción">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.servicesTMP.description"
                            required
                            placeholder="Ingresar una descripción">
              </b-form-input>
            </b-form-group>
          </b-col>            
        </b-row>
      </b-col>
      <b-col md="4">
        <b-row>
          <b-col md="4">
            <b-form-group label="Cantidad" v-if="isInvoice || showQuantity">
              <b-form-input type="number"
                            size="sm"
                            v-model="crud.form.servicesTMP.quantity"
                            required
                            min="1"
                            placeholder="Ingresar cantidad">
              </b-form-input>
            </b-form-group>
          </b-col>         
          <b-col md="6">
            <b-form-group label="Importe" v-if="getCurrency">
              <b-input-group :prepend="getCurrency.symbol" size="sm">
                <b-form-input type="number"
                              v-model="crud.form.servicesTMP.price"
                              required
                              min="0"
                              step="0.01"
                              placeholder="Ingresar un Importe">
                </b-form-input>
              </b-input-group>
            </b-form-group>
            <b-form-group label="Importe" v-else>
              <b-form-input type="number"
                            size="sm"
                            v-model="crud.form.servicesTMP.price"
                            required
                            min="0"
                            step="0.01"
                            placeholder="Ingresar un Importe">
              </b-form-input>
            </b-form-group>
          </b-col> 
          <b-col md="1" class="crud-services-button-add-item">
            <b-button @click="addItem()" 
                      v-b-tooltip.hover 
                      size="sm"
                      :variant="crud.form.servicesTMP.button.variant"
                      :title="crud.form.servicesTMP.button.title">
              <i :class="crud.form.servicesTMP.button.icon"></i>
            </b-button>                                                         
          </b-col>  
        </b-row> 
      </b-col>
      <b-col lg="12" v-if="crud.form.servicesTMP.editing" class="block-services-add-div">
        <b-icon icon="circle-fill" animation="throb" font-scale="2"></b-icon>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col md="12">
        <b-table class="mb-0 crud-services-customers-table-services"
                ref="table"
                responsive
                head-variant="dark"
                :hover="true"
                :small="true"
                :fixed="false"
                :items="tableServices.items"
                :fields="tableServices.fields">  

          <template v-slot:cell(services)="data">  
            <div v-if="isInvoice">
              {{data.item.description}}
            </div>
            <div v-else>
              {{data.item.services.name}}
            </div>            
          </template>                

          <template v-slot:cell(list)="data">     
            <span v-if="data.item.price_list">
              {{data.item.price_list.reference}}
            </span>
          </template>  

          <template v-slot:cell(date_init)="data">  
            {{moment(data.item.date_init).format('DD/MM/YYYY')}}
          </template>  

          <template v-slot:cell(quantity)="data">            
            <div v-if="isInvoice">
              {{data.item.quantity}}
            </div>
            <div v-else>
              <div v-if="data.item.services.mode=='hours'">
                {{data.item.quantity}} {{data.item.services.mode}}
              </div>
            </div>
          </template>                                                      

          <template v-slot:cell(price)="data">  
            {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.services.list.currency.code}).format(data.item.price)}}
          </template>

          <template v-slot:cell(restart)="data">              
            <b-badge v-if="data.item.restart" variant="warning">SI</b-badge>
          </template>

          <template v-slot:cell(f_action)="data">  
            <b-dropdown right 
                        text="" 
                        size="sm" 
                        variant="outline-dark"  
                        :disabled="crud.form.servicesTMP.button.editing"
                        @show="setConfigTableDetailsShow"
                        @hide="setConfigTableDetailsHide">

              <b-dropdown-item @click="restartItem(data.item, data.index)" v-if="!data.item.restart && isEdit && !isInvoice">
                <b-icon icon="arrow-clockwise"></b-icon> Reiniciar Actividad
              </b-dropdown-item>
              <b-dropdown-item @click="noRestartItem(data.item, data.index)" v-if="data.item.restart && isEdit && !isInvoice">
                <b-icon icon="arrow-counterclockwise"></b-icon> Cancelar Reinicio
              </b-dropdown-item>              

              <b-dropdown-header v-if="isEdit">Acciones</b-dropdown-header>  
              <b-dropdown-item @click="editItem(data.item, data.index)">
                <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
              </b-dropdown-item>
              <b-dropdown-item @click="deleteItem(data.index)" style="color:red;">
                <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
              </b-dropdown-item>     
            </b-dropdown>                                
          </template> 
        </b-table>
      </b-col>              
    </b-row>

    <!-- ########################### -->
    <!-- #####     MODALES     ##### -->
    <!-- ########################### -->

    <!-- CRUD MULTIPLE LIST PRICE -->
    <b-modal v-model="modal.multipleListPrice.active"
            header-bg-variant="dark"
            header-text-variant="white"            
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close>
      <div slot="modal-header">
        {{this.modal.multipleListPrice.title}}
      </div>

      <b-row>
        <b-col md="12" class="mb-2">          
          <div class="detail-product-multiple-list-price-title">
            <span class="detail-product-multiple-list-price-code">
              {{this.modal.multipleListPrice.code}}   
            </span><br>            
            {{this.modal.multipleListPrice.product}}            
          </div>              
        </b-col>        
        <b-col lg="12">          
          <b-table 
            class="mb-0"
            responsive="sm"
            head-variant="dark"
            :hover="true"
            :small="true"
            :fixed="false"
            :items="tableMultipleListPrice.items"
            :fields="tableMultipleListPrice.fields">
            
            <template v-slot:cell(list)="data">  
              <div v-if="data.item.list">
                {{data.item.list.reference}}
              </div>
            </template>  

            <template v-slot:cell(price)="data">                
              <div v-if="data.item && data.item.list && data.item.list.currency">
                {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(data.item.price_simple)}}
              </div>
            </template>

            <template v-slot:cell(action)="data">  
              <b-button variant="outline-dark" size="sm" @click="selectMultipleListPrice(data.item)">
                Seleccionar
                <b-icon icon="chevron-double-right"></b-icon>
              </b-button>
            </template>                          
          </b-table>
        </b-col>
      </b-row>

      <div slot="modal-footer">
        <b-button variant="outline-secondary" size="sm" class="mr-1" @click="cancelMultipleListPrice()">Cancelar</b-button>        
      </div>
    </b-modal>   

  </div>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'
  import Param from '@/config/parameters'
  import moment from 'moment'

  export default {
    props: {
      customer_id:{
        type: Number,
        required: true,
      },
      price_list_id:{
        type: Number,
        default: 0,
      },            
      date_init: {
        type: String,
        required: true,
      },
      details: {
        type: Array,
        required: true,
      },
      isEdit: {
        type: Boolean,
        default: false,        
      },
      isInvoice: {
        type: Boolean,
        default: false,                
      },
      forceUpdate: {
        type: Number,
        default: 0,
      },      
    },
    components: {
      FindObject,      
    },
    data: () => {
      return {      
        tableServices : {
          items: [],
          fields: []
        },         
        tableMultipleListPrice : {
          items: [],
          fields: [],
        },          
        crud: {
          form: {
            services: [],
            servicesTMP: {
              id: 0,
              services: null,
              services_id: 0,
              date_init: '',
              quantity: 0,
              price: 0,        
              description: '',
              forceRenderSelectServices: 0, 
              price_list_id: 0,
              price_list: null,                    
              button: {
                icon: 'fa fa-plus',
                title: 'Agreagr Item',
                variant: 'success',
                editing: false
              },              
              editing: false,
              restart: false,   
              project_id: 0,
              date_renewal: '',
              canceled: false,
              observations: '',
            }
          },           
        },
        editing: {
          status: false,
        },
        modal: {
          multipleListPrice: {
            active: false,
            title: '', 
            code: '',
            product: '',
          },       
        },                                                 
      }
    },    
    computed: {
      conditionServicesActive() {
        return [{field: 'active', condition: true}];
      },
      
      showQuantity() {
        if(this.crud.form.servicesTMP.services) {
          if(this.crud.form.servicesTMP.services.mode == 'hours') {
            return true
          } 
        }
        return false        
      }, 
      getCurrency() {
        var currency = null
        if(this.crud.form.servicesTMP.services) {
          if(this.crud.form.servicesTMP.services.list) {
            if(this.crud.form.servicesTMP.services.list.currency) {
              currency = this.crud.form.servicesTMP.services.list.currency        
            }
          }
        }
        return currency                
      }
    },   
    mounted() {      
      this.loadFieldsTable()
      this.loadDetails()
    },
    methods: {
      loadFieldsTable() {
        // TABLE DETAILS
        this.tableServices.fields.push({key: 'services', label: 'Servicio', class:"align-middle", width:"150px"})            
        this.tableServices.fields.push({key: 'list', label: 'Lista', class:"align-middle text-center"})             
        if(!this.isInvoice) {
          this.tableServices.fields.push({key: 'date_init', label: 'Inicio', class:"align-middle text-center", width:"100px"})           
        }
        
        this.tableServices.fields.push({key: 'quantity', label: 'Cantidad', class:"align-middle text-center", width:"100px"}) 
        this.tableServices.fields.push({key: 'price', label: 'Importe', class:"align-middle text-right", width:"100px"}) 

        if(this.isEdit && !this.isInvoice) {
          this.tableServices.fields.push({key: 'restart', label: 'Reinicio', class:"align-middle text-center", width:"70px"}) 
        }        

        this.tableServices.fields.push({key: 'f_action', label:'', class:"align-middle text-right", width:"100px"}) 

        // TABLE MULTIPLE PRICE
        this.tableMultipleListPrice.fields.push({key: 'list', label: 'Lista', class:"align-middle"})
        this.tableMultipleListPrice.fields.push({key: 'price', label: 'Precio', class:"align-middle text-right"})
        this.tableMultipleListPrice.fields.push({key: 'action', label: '', class:"align-middle text-right"})                
      },
      getRowCount (items) {
        return items.length
      },        

      // MULTIPLE LIST PRICE
      selectMultipleListPrice(item) {               
        this.setFullServices(item, null)   
        
        this.modal.multipleListPrice.active = false
      },
      cancelMultipleListPrice() {        
        this.crud.form.servicesTMP.editing = false
        
        this.crud.form.servicesTMP.services_id = 0
        this.crud.form.servicesTMP.services = null        

        this.crud.form.servicesTMP.id = 0
        this.crud.form.servicesTMP.date_init = ''
        this.crud.form.servicesTMP.quantity = 0
        this.crud.form.servicesTMP.price = 0
        this.crud.form.servicesTMP.description = ''
        this.crud.form.servicesTMP.project_id = 0
        this.crud.form.servicesTMP.date_renewal = ''        

        this.modal.multipleListPrice.active = false

        this.crud.form.servicesTMP.forceRenderSelectServices = 0
      },  

      addItem(){        
        if(!this.crud.form.servicesTMP.services) {
          this.$awn.alert('No se cargó el servicio')          
          return false
        }

        if(!this.isInvoice) {
          if(!this.crud.form.servicesTMP.date_init) {
            this.$awn.alert('No se cargó la fecha de inicio')          
            return false
          }

          if (this.crud.form.servicesTMP.quantity < 1 && 
              this.crud.form.servicesTMP.services.mode=='hours') {
            this.$awn.alert('Cantidad de Horas Inválida')          
            return false
          } 
        } else {
          if (this.crud.form.servicesTMP.quantity < 1) {
            this.$awn.alert('Cantidad no puede ser a cero')          
            return false
          }          
        }
          
        // precio
        if (this.crud.form.servicesTMP.price.toString() == '' || parseFloat(this.crud.form.servicesTMP.price)<0) {                   
          this.$awn.alert('El importe no puede ser menor a cero (0)')          
          return false
        }        

        if(!this.validDuplicatedItem()) {
          this.$awn.alert('Item Duplicado') 
          return false          
        }

        var arrItem = { 
          id: this.crud.form.servicesTMP.id,
          services: this.crud.form.servicesTMP.services, 
          date_init: this.crud.form.servicesTMP.date_init, 
          quantity: parseFloat(this.crud.form.servicesTMP.quantity), 
          price: parseFloat(this.crud.form.servicesTMP.price), 
          description: this.crud.form.servicesTMP.description,
          project_id: this.crud.form.servicesTMP.project_id,
          date_renewal: this.crud.form.servicesTMP.date_renewal,
          price_list: this.crud.form.servicesTMP.price_list,
          canceled: this.crud.form.servicesTMP.canceled,
          observations: this.crud.form.servicesTMP.observations
        }

        this.crud.form.services.push(arrItem)             
        this.$emit('load-detail', this.crud.form.services)

        this.tableServices.items = this.crud.form.services
        this.crud.form.servicesTMP.forceRenderSelectServices = 0

        this.editing.status = false
        this.loadServices(null)

        this.crud.form.servicesTMP.button.title = "Agregar Item"
        this.crud.form.servicesTMP.button.icon = "fa fa-plus"
        this.crud.form.servicesTMP.button.variant = "success"
        this.crud.form.servicesTMP.button.editing = false    
        
        this.crud.form.servicesTMP.editing = false
      },
      editItem (item, index) {
        this.crud.form.servicesTMP.editing = true
                                                 
        this.crud.form.servicesTMP.services_id = item.services.id
        this.crud.form.servicesTMP.services = item.services
        this.crud.form.servicesTMP.forceRenderSelectServices = item.services.id    
                   
        this.crud.form.servicesTMP.id = item.id    
        this.crud.form.servicesTMP.date_init = item.date_init    
        this.crud.form.servicesTMP.quantity = item.quantity    
        this.crud.form.servicesTMP.price = item.price   
        this.crud.form.servicesTMP.description = item.description  
        this.crud.form.servicesTMP.project_id = item.project_id
        this.crud.form.servicesTMP.date_renewal = item.date_renewal      
        this.crud.form.servicesTMP.price_list = item.price_list
        this.crud.form.servicesTMP.canceled = item.canceled
        this.crud.form.servicesTMP.observations = item.observations

        this.editing.status = true
        this.loadServices(item.services)
      
        this.crud.form.servicesTMP.button.title = "Editar Item"
        this.crud.form.servicesTMP.button.icon = "fa fa-save"
        this.crud.form.servicesTMP.button.variant = "warning"
        this.crud.form.servicesTMP.button.editing = true
                     
        this.crud.form.services.splice(index, 1)

        this.$emit('load-detail', this.crud.form.services)
      },
      deleteItem (index) {
        this.crud.form.services.splice(index, 1)
        this.$emit('load-detail', this.crud.form.services)
      },
      restartItem(item, index) {        
        this.crud.form.services[index].restart = true   
        this.$emit('load-detail', this.crud.form.services)     
        this.$refs.table.refresh()        
      },
      noRestartItem(item, index) {
        this.crud.form.services[index].restart = false
        this.$emit('load-detail', this.crud.form.services)   
        this.$refs.table.refresh()
      },      

      loadServices (object) {
        if(object){
          this.crud.form.servicesTMP.editing = true

          this.crud.form.servicesTMP.services = object
          this.crud.form.servicesTMP.services_id = object.id                  
          
          if(!this.crud.form.servicesTMP.description)
            this.crud.form.servicesTMP.description = object.name    

          this.crud.form.servicesTMP.forceRenderSelectServices = object.id                    

          this.getFullServices(object, null, this.editing.status)                                    
        } else {
          this.crud.form.servicesTMP.services_id = 0
          this.crud.form.servicesTMP.services = null        

          this.crud.form.servicesTMP.id = 0
          this.crud.form.servicesTMP.date_init = ''
          this.crud.form.servicesTMP.quantity = 0
          this.crud.form.servicesTMP.price = 0
          this.crud.form.servicesTMP.description = ''
          this.crud.form.servicesTMP.project_id = 0
          this.crud.form.servicesTMP.date_renewal = ''
        }
      },

      loadDetails() {        
        if(this.details) {
          this.details.forEach(element => {                          
            this.getFullServices(element.service, element)                                                         
          });          
          this.tableServices.items = this.crud.form.services
          this.$emit('load-detail', this.crud.form.services)              
        }
      },

      getFullServices(service, objectDetail, isEditing=false) {                                    
        if(this.customer_id) {                
          var listPriceQuery = 0
          if(isEditing) {   
            listPriceQuery = this.crud.form.servicesTMP.price_list.id
          } else {
            if(this.price_list_id) {
              listPriceQuery = this.price_list_id
            } else {
              if(objectDetail) {                
                listPriceQuery = objectDetail.price_list.id
              }              
            }            
          }

          var result = serviceAPI.obtenerPrecioServicio({
            services: service,
            customer_id: this.customer_id,
            price_list_id: listPriceQuery
          })        
          result.then((response) => {
            var data = response.data   

            this.tableMultipleListPrice.items = data
            
            if (Array.isArray(data)) {              
              this.modal.multipleListPrice.title = "Selección de Lista de Precios"
              this.modal.multipleListPrice.code = service.code
              this.modal.multipleListPrice.product = service.name
              this.modal.multipleListPrice.active = true                                    
            } else {                  
              this.setFullServices(data,objectDetail)     
            }
          })  
          .catch(error => {
            this.crud.form.servicesTMP.editing = false
          });                                      
        } else {                      
          this.crud.form.servicesTMP.services = null
        }                
      },
      setFullServices(data, objectDetail) {
        this.crud.form.servicesTMP.services = data   
        
        if(!this.crud.form.servicesTMP.price) {
          this.crud.form.servicesTMP.price = this.crud.form.servicesTMP.services.price_simple
        }  
        if(!this.crud.form.servicesTMP.date_init) {
          this.crud.form.servicesTMP.date_init = this.date_init
        }            
        
        if(data.list) {
          this.crud.form.servicesTMP.price_list_id = data.list.id
          this.crud.form.servicesTMP.price_list = data.list                  
        }  

        /* Si este objeto esta cargado, se carga el detalle */        
        if(!this.editing.status) {   
          if(objectDetail) {              
            this.crud.form.servicesTMP.id = objectDetail.id
            this.crud.form.servicesTMP.date_init = objectDetail.date_init
            this.crud.form.servicesTMP.quantity = objectDetail.quantity 
            this.crud.form.servicesTMP.price = objectDetail.price
            this.crud.form.servicesTMP.description = objectDetail.description              
            this.crud.form.servicesTMP.project_id = objectDetail.project_id
            this.crud.form.servicesTMP.date_renewal = objectDetail.date_renewal
            this.crud.form.servicesTMP.price_list_id = objectDetail.price_list.id
            this.crud.form.servicesTMP.price_list = objectDetail.price_list                   
            this.crud.form.servicesTMP.canceled = objectDetail.canceled
            this.crud.form.servicesTMP.observations = objectDetail.observations

            var arrItem = { 
              id: this.crud.form.servicesTMP.id,
              services: this.crud.form.servicesTMP.services, 
              date_init: this.crud.form.servicesTMP.date_init, 
              quantity: parseFloat(this.crud.form.servicesTMP.quantity), 
              price: parseFloat(this.crud.form.servicesTMP.price), 
              description: this.crud.form.servicesTMP.description,         
              project_id: this.crud.form.servicesTMP.project_id,        
              date_renewal: this.crud.form.servicesTMP.date_renewal,             
              price_list: this.crud.form.servicesTMP.price_list,
              canceled: this.crud.form.servicesTMP.canceled,
              observations: this.crud.form.servicesTMP.observations
            }
            this.crud.form.services.push(arrItem)  
            this.loadServices(null)
          }    
        }
        /* fin carga detalle*/        

        this.crud.form.servicesTMP.editing = false
      },

      setConfigTableDetailsShow() {
        var arrTable = document.getElementsByClassName('crud-services-customers-table-services')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = '200px'
        }        
      },
      setConfigTableDetailsHide() {
        var arrTable = document.getElementsByClassName('crud-services-customers-table-services')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },        

      // VALID DUPLICATED
      validDuplicatedItem() {        
        var item = this.crud.form.servicesTMP.services
        var status = true        
        if(this.crud.form.services) {          
          this.crud.form.services.forEach(element => {                                                
            if(element.services.id == item.id) {
              status = false
            }                          
          });
        }
        return status
      },      
    }
  }
</script>
<style scoped>
  .crud-services-customers-table-services {
    min-height: 100px;
  }   
  .crud-services-button-add-item {
    margin-top: 30px;
  }
  .block-services-add {
    background: white;
    filter: brightness(0.4);
    border-radius: 15px;
    padding-top: 10px;
    pointer-events: none;
    opacity: 0.5;
  }  
  .block-services-add-div {
    position: absolute;
    text-align: center;
    margin-top: 20px;    
  }  
</style>    